"use strict";

import loadScript from "@elements/load-script";

export function init () {
    if (typeof orgCode !== 'undefined') {
        loadScript('https://resc.deskline.net/DW5/start/'+orgCode+'/'+ _config.toscTagId +'/index.js');

        window.dw = window.dw || function () { (dw.q = dw.q || []).push(arguments) };
        let settings = {
            "lang": toscLang,
            "profileOverrides": [],
            "target": "tosc5target"
        };

        if (typeof toscCustomPoi !== 'undefined') {
            settings.profileOverrides.push("general.customPOIs=toscCustomPoi");
            settings.profileOverrides.push("accommodation.list.sort.sortOptions[0].asc.sort='-bookable,poidist(mainSpot),random'");
            settings.profileOverrides.push("accommodation.list.sort.sortOptions[4].asc.sort='poidist(mainSpot),random'");
        }

        let context = {
            "context": {}
        }

        if (typeof toscTargetRoute !== 'undefined') {
            context = {
                "targetRoute":[
                    toscTargetRoute
                ],
            }
            settings = {
                ...settings,
                context
            }
        }

        if (typeof toscUrlOptionalParams !== 'undefined') {
            context = {
                ...context,
                "urlOptionalParams": toscUrlOptionalParams
            }
            settings = {
                ...settings,
                context
            }
        }

        if (typeof toscBaseHref !== 'undefined') {
            settings = {
                ...settings,
                context,
                "baseHref": toscBaseHref
            }
        }

        dw('settings',_config.toscTagId, settings);
    }

}